<template>
    <v-app-bar app color="black" dark flat fixed>
        <h1 style="font-family: Code; margin: 0 auto"><b>Randy Mbiya</b></h1>

        <div id="menu-icon">
            <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        </div>
    </v-app-bar>

    <div id="v-navigation-drawer">
        <v-navigation-drawer  v-model="drawer" absolute bottom temporary>
            <v-list nav dense>
                <v-list-item-group v-model="group" active-class="deep-purple--text text--accent-4">
                    <v-list-item class="hover-bg-purple">
                        <v-list-item-title text @click="scroll('portfolio'); drawer = !drawer" class="text-black pointer zoom">
                            <div class="link-hover">Compétences & projets réalisés</div>
                        </v-list-item-title>
                    </v-list-item>

                    <v-list-item class="hover-bg-purple">
                        <v-list-item-title text @click="scroll('about'); drawer = !drawer" class="text-black pointer zoom">
                            <div class="link-hover">À propos de moi</div>
                        </v-list-item-title>
                    </v-list-item>

                    <v-list-item class="hover-bg-purple">
                        <v-list-item-title text @click="scroll('contact'); drawer = !drawer" class="text-black pointer zoom">
                            <div class="link-hover">Me contacter</div>
                        </v-list-item-title>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>
    </div>

    <nav class="navbar navbar-expand-lg bg-body-tertiary">
        <div class="container-fluid">
            <h1 style="font-family: cyberspace; margin: 0 auto"><b>Randy Mbiya</b></h1>
            
            <div id="menu-icon">
                <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
            </div>
        </div>
    </nav>

    <div id="menu" class="text-black">
        <div class="d-flex justify-content-around text-center">
            <div @click="scroll('portfolio')" class="hover-bg-purple pointer-item p-2 w-100">
                <a class="link-hover">Compétences & projets réalisés</a>
            </div>

            <div @click="scroll('about')" class="hover-bg-purple pointer-item p-2 w-100">
                <a class="link-hover">À propos de moi</a>
            </div>

            <div @click="scroll('contact')" class="hover-bg-purple pointer-item p-2 w-100">
                <a class="link-hover">Me contacter</a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'NavBar',

        methods: {
            scroll(refName) {
                const element = document.getElementById(refName);
                element.scrollIntoView({behavior: "smooth"});
            }
        },

        data: () => ({
            drawer: false,
            group: null,
        }),

        watch: {
            group () {
            this.drawer = false
            },
        },
    }
</script>

<style>
    @font-face {
        font-family: 'Code';
        src: url('../assets/fonts/Code-Bold.woff2') format('woff2');
        font-weight: bold;
        font-style: normal;
        font-display: swap;
    }
    @font-face {
        font-family: 'Code';
        src: url('../assets/fonts/Code-Light.woff2') format('woff2');
        font-weight: 300;
        font-style: normal;
        font-display: swap;
    }

    .hover-bg-purple:hover {
        background-color: #F3E5F5
    }

    .pointer-item:hover {
        cursor: pointer;
    }

    .link-hover {
        text-decoration: none;
    }

    .link-hover {
        color: black;
    }

    .link-hover:hover {
        color: purple;
    }

    #menu-icon {
        display: none;
    }

    .pointer {
        cursor: pointer;
    }

    .zoom:hover {
        width: 700px;
    }

    @media only screen and (max-width: 735px) {
        #menu {
            display: none;
        }

        #menu-icon {
            display: block;
        }
    }

    @media only screen and (min-width: 735px) {
        
    }
</style>