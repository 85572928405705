<template>
  <v-app id="home">
    <NavBar />
    <v-container fluid>
      <div class="bg-white text-center d-flex align-center justify-content-center" style="width: 100vw; height: 85vh;">
        <div class="row w-100">
            <div class="col-md-6 p-4">
              <v-img src="../../src/assets/Randy.png" :width="270" style="margin: 0 auto"></v-img>
            </div>

            <div class="col-md-6 p-4 d-flex align-center justify-center">
              <div class="d-flex justify-center">
                <div>
                  <h1 class="text-black">Bonjour,</h1>
                  <h1 class="text-black">Je m'appelle Randy Mbiya</h1>
                  <span class="text-black">Analyste Business Intelligence</span><br>
                  <span class="text-black">& Webmaster Développeur [JavaScript]</span><br>
                  <!-- <v-btn @click="link('CV.pdf')" title dark color="purple" class="mt-4 mb-8">
                    Accéder à mon CV
                  </v-btn> -->
                </div>
              </div>
            </div>
        </div>
      </div>



      <div class="text-center bg-white text-black" id="portfolio">
        <h2 class="m-0">Mes compétences</h2>
      </div>
      <v-col cols="12" class="bg-white">
        <div class="first text-black" id="project">
          <v-row>
            <v-col cols="">
              <div class="child">
                <v-btn icon="fa-solid fa-chart-line" color="purple" class=""></v-btn>
                <h3 class="ml-3 mt-4 ">BI</h3>
                <p class=" ml-3 mt-4 text-caption">
                  - Visualisation de données
                  <br>
                  Qlik Sense | Qlik View | Tableau | Metabase
                  <br><br>
                  - Préparation de données
                  <br>
                  Alteryx | SAS | SQL | Excel
                  <br><br>
                </p>
              </div>
              <div class="child">
                <v-btn icon="fa-solid fa-window-maximize" color="purple" class="text-white"></v-btn>
                <h3 class="ml-3 mt-4 ">Frontend</h3>
                <p class=" ml-3 mt-4 text-caption">
                  - FIGMA
                  <br>
                  <small>ILLUSTRATOR | PHOTOSHOP</small>
                  <br><br>
                  - HTML
                  <br>
                  <small>VSCODE</small>
                  <br><br>
                  - CSS / SCSS
                  <br>
                  <small>BOOTSTRAP | VUETIFY | BOOTSTRAPVUE</small>
                  <br><br>
                  - JAVASCRIPT
                  <br>
                  <small>REACT | VUEJS | NODEJS | JQUERY</small>
                  <br><br>
                  - CMS
                  <br>
                  <small>PRESTASHOP | WORDPRESS | SHOPIFY</small>
                </p>
              </div>
              <div class="child">
                <v-btn icon="fa-solid fa-gears" color="purple" class=""></v-btn>
                <h3 class="ml-3 mt-4 ">Backend</h3>
                <p class=" ml-3 mt-4 text-caption">
                  - PHP
                  <br>
                  <small>SYMFONY | LARAVEL | API PLATFORM</small>
                  <br><br>
                  - SQL
                  <br>
                  <small>POSTGRESQL | MYSQL | MONGODB</small>
                </p>
              </div>
              <div class="child">
                <v-btn icon="fa-solid fa-code-branch" color="purple" class=""></v-btn>
                <h3 class="ml-3 mt-4 ">Workflow</h3>
                <p class=" ml-3 mt-4 text-caption">
                  - DOCKER
                  <br><br>
                  - GIT (VERSIONNING & CI-CD)
                  <br>
                  <small>GITBASH | GITLAB | GUTHUB</small>
                  <br><br>
                  - TRELLO
                  <br><br>
                  - GESTION DE PROJET
                  <br>
                  <small>METHODE AGILE SCRUM</small>
                </p>
              </div>
              <div class="child">
                <v-btn icon="fa-solid fa-face-smile" color="purple" class=""></v-btn>
                <h3 class="ml-3 mt-4 ">Personnalité</h3>
                <p class=" ml-3 mt-4 text-caption">
                  - Esprit d'analyse et résolution de problèmes
                  <br><br>
                  - Curiosité et apprentissage continu
                  <br><br>
                  - Rigueur et précision
                  <br><br>
                  - Capacité à travailler en équipe et communication
                  <br><br>
                  - Polyvalence et adaptabilité
                  <br><br>
                </p>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>


      <div class="text-center bg-white text-black pb-5">
        <h2 class="m-0">Projets réalisés</h2>
      </div>
      <v-col cols="12" id="page">
        <v-row class="pre bg-white">
          <v-col>
             <v-card class="mx-auto select-card" @click="link('https://www.lockingindustry.com/')">
              <v-img src="site6.png" height="200px" cover></v-img>

              <v-card-title>Projet Web pour Locking Industry</v-card-title>

              <v-card-subtitle>
                Ermenonville (60) | 2024
              </v-card-subtitle>
              <v-card-text>
                Développement de site vitrine suivant une maquette et la charte graphique de l'entreprise. <br><br><b><i class="fas fa-globe fa-beat-fade" style="color: green"> En ligne</i></b>
              </v-card-text>
            </v-card>
          </v-col>
          
          <v-col>
            <v-card class="mx-auto select-card" @click="link('https://christianmanzala.eu/')">
              <v-img src="site5.png" height="200px" cover></v-img>

              <v-card-title>Projet Web pour Christian Manzala</v-card-title>

              <v-card-subtitle>
                Issy-les-moulineaux (92) | 2023
              </v-card-subtitle>
              <v-card-text>
                Prise en charge de la conception frontend et backend du site du créateur mode. Prise des besoins de l'entreprise et force de proposition. <br><br><b><i class="fas fa-globe fa-beat-fade" style="color: green"> En ligne</i></b>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col>
            <v-card class="mx-auto" >
              <v-img src="site4.png" height="200px" cover></v-img>

              <v-card-title>Projet Web pour La Mutuelle Générale</v-card-title>

              <v-card-subtitle>
                Paris (75) | 2021
              </v-card-subtitle>
              <v-card-text>
                Prise en charge de l'élaboration et de la maintenance d'un site intranet. <br><br><b><i class="fa-solid fa-lock"></i> [Privé]</b>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col>
            <v-card class="mx-auto" >
              <v-img src="site2.png" height="200px" cover></v-img>

              <v-card-title>Projet Web pour le Master II</v-card-title>

              <v-card-subtitle>
                Paris (75) | 2022
              </v-card-subtitle>
              <v-card-text>
                Conception d'un site d'échange gratuit de jeux vidéo physique, à l'issue du projet annuel du Master II. <br><br><b><i class="fa-solid fa-lock"></i> [Mis en ligne temporairement pour examen scolaire]</b>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col>
            <v-card class="mx-auto">
              <v-img src="site3.png" height="200px" cover></v-img>

              <v-card-title>Projet Web personnel</v-card-title>

              <v-card-subtitle>
                Thiais (94) | 2020
              </v-card-subtitle>
              <v-card-text>
                Développement de mon site e-commerce. <br><br><b><i class="fa-solid fa-lock"></i> [En reconception]</b>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col>
            <v-card class="mx-auto" >
              <v-img src="site1.png" height="200px" cover></v-img>

              <v-card-title>Projet Web pour le Master I</v-card-title>

              <v-card-subtitle>
                Paris (75) | 2021
              </v-card-subtitle>
              <v-card-text>
                Conception d'un CMS dédié à la création de site pour cinémas locaux, à l'issue du projet annuel du Master I. <br><br><b><i class="fa-solid fa-lock"></i> [Mis en ligne temporairement pour examen scolaire]</b>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>



      <v-col cols="12">
          <v-row class="hire">
            <v-col>
              <div style="text-align: center;">
                <h1 class="mt-9">Ensemble nous développerons vos fabuleux projet.</h1>
                <p class="text-grey">
                  Un formulaire de contact est disponible en bas de page
                </p>
              </div>
            </v-col>
          </v-row>
      </v-col>



      <v-col cols="12" class="mt-16 mb-5" id="about">
        <div>
          <v-row>
            <v-col cols="12" sm="6">
              <div class="egg2"></div>
            </v-col>

            <v-col cols="12" sm="6" style="background-color: black">
              <h2 class="text-white mb-8">À propos de moi</h2>

              <p class="text-white">Je suis passionné par le monde numérique depuis mon adolescence. Très jeune, j'ai nourri l'ambition de créer un jeu en ligne, une sorte de monde virtuel pour adolescents, accessible via un navigateur. Cette passion m'a conduit à explorer le développement web à travers de nombreux tutoriels sur YouTube, et j'ai rapidement su que je voulais poursuivre des études dans ce domaine après le bac. <br><br> Aujourd'hui, je suis titulaire d'un <b class="text-purple">Master d'Expert en Ingénierie du Web</b> de l'ESGI. Mes compétences se concentrent principalement sur le <b class="text-purple">développement Frontend JavaScript</b> et <b class="text-purple">Backend PHP | SQL</b>. Ma formation et mes expériences professionnelles m'ont permis de maîtriser divers outils et technologies, et de m'adapter à des projets complexes et variés. <br><br> Mon intérêt pour la <b class="text-purple">visualisation Data</b> a émergé lors de mon alternance à La Mutuelle Générale. Après avoir terminé ma mission en développement web, j'ai été transféré dans l'équipe de pilotage commercial, spécialisée en Business Intelligence. Là, j'ai eu l'opportunité de me former et d'assister le chargé d'étude dans des projets de BI & visualisation de données. Cette expérience a enrichi mon parcours en m'apportant une nouvelle dimension dans l'analyse et la représentation des données. <br><br> Je suis aussi passionné par les domaines du <b class="text-purple">web</b> et de la <b class="text-purple">BI</b>, et je cherche constamment à approfondir mes connaissances et à relever de nouveaux défis dans ces secteurs.</p>
            </v-col>
          </v-row>
        </div>
      </v-col>



      <v-col cols="12" sm="12" class="text-white px-16 mb-5" id="contact">
        <v-row>
          <v-col  style="text-align: center">
            <div class="child">
              <h1>Coordonnées</h1>
              <v-btn
                icon="fas fa-map-marker-alt"
                color=""
                class="mt-10"
                variant="outlined"
              ></v-btn
              ><br />
              <span class="text-caption">Thiais, Val-de-Marne (94)</span
              ><br />
              <v-btn
                icon="fas fa-phone-alt"
                color=""
                class="mt-10"
                variant="outlined"
              ></v-btn
              ><br />
              <span class="text-caption">07 67 14 81 83</span> <br />
              <v-btn
                icon="fas fa-envelope"
                color=""
                class="mt-10"
                variant="outlined"
              ></v-btn
              ><br />
              <span class="text-caption">mbiya.randy@gmail.com</span> <br />
            </div>
          </v-col>
          
          <v-col cols="12" sm="8">
            <h1 class="mt-8">Envoyez-moi votre message</h1>
            <v-divider></v-divider>
            <span class="text-caption">Je vous répondrai dans les meilleurs délais</span>

            <form name="form" ref="form" @submit.prevent="sendEmail">
              
              <input type="text" name="user_name" placeholder="Votre nom">
              
              <input type="email" name="user_email" placeholder="Votre Email">
              
              <textarea name="message" cols="30" rows="5" placeholder="Message..."></textarea>
              
              <input type="submit" value="Envoyer">
            </form>
          </v-col>
        </v-row>
      </v-col>
    </v-container>
    
    <v-dialog v-model="dialog_success" transition="dialog-bottom-transition" max-width="600">
      <v-card>

        <v-toolbar color="purple" dark><span style="width: 100%" class="text-center">Message bien transmis !</span></v-toolbar>

        <v-card-text>
          <div class="text-h3 pa-12">Merci de m'avoir contacté. Je vous répondrai au plus vite :)</div>
        </v-card-text>

        <v-card-actions class="justify-end">
          <v-btn text @click="dialog_success = false; reloadPage()">Fermer</v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_failure" transition="dialog-bottom-transition" max-width="600">
      <v-card>

        <v-toolbar color="red" dark><span style="width: 100%" class="text-center">Erreur !</span></v-toolbar>

        <v-card-text>
          <div class="text-h4 pa-12">La transmission du message <br>a échouée.</div>
          <br>
          Soyez sûr d'avoir bien rempli : Nom, Email & Message
        </v-card-text>

        <v-card-actions class="justify-end">
          <v-btn text @click="dialog_failure = false;">Fermer</v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_failure_api" transition="dialog-bottom-transition" max-width="600">
      <v-card>

        <v-toolbar color="red" dark><span style="width: 100%" class="text-center">Erreur !</span></v-toolbar>

        <v-card-text>
          <div class="text-h4 pa-12">La transmission du message a echouée.</div>
          <br>
          Un problème est survenu, merci de réessayer plus tard.
        </v-card-text>

        <v-card-actions class="justify-end">
          <v-btn text @click="dialog_failure_api = false;">Fermer</v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>

    <FooterView />
  </v-app>
</template>

<script>
// Components
import NavBar from '@/components/NavBar.vue';
import FooterView from '@/components/FooterView.vue';
import emailjs from 'emailjs-com';

export default {
  name: 'HomeView',

  components: {
    NavBar,
    FooterView
  },

  data () {
    return {
      loader: "bars",
      dialog_success: false,
      dialog_failure: false,
      dialog_failure_api: false
    }
  },

  methods: {
    scroll(refName) {
        const element = document.getElementById(refName);
        element.scrollIntoView({behavior: "smooth"});
    },

    link(url) {
      window.open(url, '_blank', 'noreferrer');
    },
    
    sendEmail() {
      var vm = this;
      var name = document.forms["form"]["user_name"].value;
      var email = document.forms["form"]["user_email"].value;
      var message = document.forms["form"]["message"].value;

      function isEmptyOrSpaces(str){
          return str === null || str.match(/^ *$/) !== null;
      }

      if (isEmptyOrSpaces(name)) {
        vm.dialog_failure = true;
      }

      else if (isEmptyOrSpaces(email)) {
        vm.dialog_failure = true;
      }

      else if (isEmptyOrSpaces(message)) {
        vm.dialog_failure = true;
      }

      else {
        let LoadingContainer = this.$refs.LoadingContainer;
          
        let loader = this.$loading.show(
          {
            container: LoadingContainer,
            loader: this.loader,
          }
        );

        emailjs.sendForm('service_i4f3l6p', 'template_b7af8de', this.$refs.form, '7vsi2_qy8qn73CPOC')
        .then((result) => {
            console.log('SUCCESS!', result.text);
            vm.dialog_success = true;
            loader.hide();
        }, (error) => {
            console.log('FAILED...', error.text);
            vm.dialog_failure_api = true;
            loader.hide();
        });
      }
    },

    reloadPage() {
      window.location.reload();
    },
  }
};
</script>

<style scoped>
  .v-container {
    padding: 0;
    background-color: black;
  }

  .egg2 {
    display: block;
    width: 356px;
    height: 340px;
    margin: 0 auto;
    background-image: url("../../src/assets/Randy2.png");
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: white;
    border-radius: 50% 50% 40% 40% / 60% 60% 40% 40%;
  }
  
  .first {
  width: 100%;
  text-align: center;
  padding: 2rem 2rem;
}
.child {
  display: inline-block;
  padding: 2rem 1rem;
  vertical-align: top;
  text-align: center;
  margin-right: 8px;
}
.imgHover {
  padding: 0 200px;
}
.pre {
  text-align: center;
  padding: 10px;
}

.pre::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
.hire {
  padding: 50px;
  background-color: #e9e9e9;
}

input[type=text], [type=email], textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
  color: white;
}

input[type=submit] {
  background-color: purple;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.select-card:hover {
  cursor: pointer;
  border: solid rgb(174, 6, 174) 2px;
}

#page .v-col {
  max-width: 500px;
}
</style>