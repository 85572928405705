<template>
    <v-footer>
        <v-card elevation="0" rounded="0" width="100%" class="bg-grey text-center">
            <v-card-text>
                <v-btn role="link" @click="link('https://www.linkedin.com/in/randy-mbiya-90b60b128/')" class="mx-4" variant="plain" color="white">Restons connecté sur LinkedIn
                    <i class="fa-brands fa-linkedin fa-2xl mb-3 ml-1" variant="plain" color="white"></i>
                </v-btn>
            </v-card-text>

            <v-card-text class="text-white">
                @ {{ new Date().getFullYear() }} —
                <strong>Randy Mbiya - Tous droits réservés</strong>
            </v-card-text>
        </v-card>
    </v-footer>
</template>
  
<script>
    export default {
        methods: {
            link(url) {
                window.open(url, '_blank', 'noreferrer');
            },
        },
    }
</script>
  
<style>
    .v-footer {
        padding: 0;
    }
</style>